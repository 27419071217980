.nomatch-container,
.match-container {
    height: 100%;
    padding: 5pt;
    width: 100%;
    display: grid;
    overflow: hidden;
    max-width: 800px;
    margin: auto;
    max-height: 100%;
    min-height: 100%;
}
.match-container {
    grid-template-rows: auto 1fr auto;
}
.nomatch-container {
    grid-template-rows: 1fr auto auto auto auto 1fr;
    text-align: center;
    font-size: xx-large;
    gap: 5%;
}
.nomatch-mutuals > div:nth-child(2) {
    overflow: auto;
    text-align: left;
    font-size: medium;
    display: flex;
}

.nomatch-mutuals > div:nth-child(2) > span {
    border: 1px solid black;
    padding: 5px;
    border-radius: 5px;
    background-color:cornsilk;
    cursor: pointer;
}

.candidate-info {
    --overflow: hidden;
}
.candidate-info > div:first-child {
    text-align: center;
    font-size: xx-large;
}

.candidate-info > div:nth-child(2) {
    display: flex;
    gap: 5%;
    justify-content: center;
    text-align: center;
}
.candidate-info > div:nth-child(2) > span {
    display: flex;
    flex-flow: column;
}
.candidate-info > div:nth-child(2) > span> span:first-child {
    opacity: 0.4;
}

.candidate-profile {
    text-align: center;
    display: flex;
    gap: 10px;
    flex-flow: column;
    overflow: auto;
}
.candidate-profile > div:nth-child(2) {
    overflow: auto;
}
.candidate-profile > div:first-child {
    opacity: 0.4;
}
.match-toolbar{
    display: flex;
    --position: absolute;
    justify-content:space-evenly;
    bottom: 5%;
    left: 0;
    right: 0;
}

.nomatch-toolbar{
    display: flex;
    justify-content:space-evenly;
}

.like-button,
.trash-button {
    border-width: 0px;
    width: 50px;
    height: 50px;
    background-color: transparent;
    background-repeat: no-repeat;
}

.trash-button {
    background-image: url('trash.svg');
    color: darkred;
}

.like-button {
    background-image: url('like.svg');
    color: darkgreen;
}