.content-container {
    grid-area: app-content;
    border: 1px solid var(--text-color);
    padding: 1em 0.5em 0.5em 0.5em;
    border-radius: 0.5em;
    position: relative;
    --margin-top: 1em;
    display: grid;
    grid-template-rows: auto 1fr;
    min-height: 100%;
    max-height: 100%;
    height: 100%;
}
.content-check-img {
    position:absolute;
    width: 1px;
    height: 1px;
    z-index: -100;
}
.content-label {
    position: absolute;
    top: -0.5em;
    left: 0.5em;
    background-color: var(--background-color);
    padding-left: 0.5em;
    padding-right: 0.5em;
}
.content-toolbar {

}
@keyframes checking{
    0% {
        color: var(--background-color);
        background-color: var(--text-color);
    }
    50% {
        color: var(--text-color);
        background-color: var(--background-color);
    }
    100% {
        color: var(--background-color);
        background-color: var(--text-color);
    }
}
button.checking {
    animation: checking infinite 5s;
}

.content-area {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 5px;
    min-height: 100%;
    max-height: 100%;
    height: 100%;
    padding: 5px;
}
.content-items {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    overflow: auto;
    justify-items: left;
    flex-wrap: nowrap;
    height: 100%;
    min-height: 100%;
    padding-right: 5px;
    border-right: 1px solid var(--text-color);
    height: 100%;
}

.content-item-form {
    overflow: auto;
    position: relative;
}

.content-item-container {
    border-width: 1px;
    padding: 5px;
    margin: 5px;
    border-radius: 5px;
    border-style: solid;
    cursor: pointer;
    min-width: 5em;
    position: relative;
    max-width: 15em;
}

.GOOD::before {
    content: "good";
    background-color: green;
    padding: 2px;
    color: white;
    font-size: small;
}

.FAIL::before {
    content: "fail";
    background-color: red;
    padding: 2px;
    color: white;
    font-size: small;
}

.WARNING::before {
    content: "warning";
    background-color: gold;
    padding: 2px;
    color: white;
    font-size: small;
}

.content-item-header {
    font-size: 120%;
}
.selected {
    color: var(--background-color);
    background-color: var(--text-color);
}

.content-item-desc {
    font-size: small;
    --overflow: scroll;
}

.content-item-type {
    position: relative;
    float: right;
}

.img-to-center{
    width: 100%;
    height: 100%;
    position: relative;
}
.content-fit-to {
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    left:0;
    top:0;
    right: 0;
    position: absolute;
}
.content-form {
    overflow: scroll;
    padding: 2px;
    grid-area: form;
}

textarea {
    width: 99%;
}

.content-form-tools {
    height: 3em;
    display: grid;
    grid-template-columns: 1fr 75px auto;
}

.content-item-changed-status {
    position: absolute;
    float: right;
    font-size: small;
    color: white;
    right: 0;
    top: 0em;
    background-color: darkred;
    border: 1px solid red;
    border-radius: 5px;
    padding: 2px;
    z-index: 1000;
}

.changes-saved {
    background-color: darkgreen;
    border-color: green;
}

.content-item-time-label {
    font-size: xx-small;
}

