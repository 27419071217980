div.info, div.error {
  position: absolute;
  width: 80%;
  height: auto;
  min-height: fit-content;
  max-height: fit-content;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: auto;
  border-radius: 5px;
  padding: 20px;
}
div.info {
  border: darkgreen 1px solid;
  background-color:lightgreen;
  color: darkgreen;
  top: unset;
}
div.error {
  border: darkred 1px solid;
  background-color:lightcoral;
  color: darkred;
}

.app-container {
  display: grid;
  gap: 1em;
  border: 0px solid var(--trust-color);
  padding: 2px;
  border-radius: 5px;
  min-width: 100%;
  min-height: 100%;
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}
@media (orientation: landscape) {
  .app-container {
    grid-template-areas: 
      "app-user app-toolbar"
      "app-orgs app-toolbar"
      "app-content app-toolbar";
      grid-template-rows: auto auto 1fr;
      grid-template-columns: 1fr auto;
    }
}

@media (orientation: portrait) {
  .app-container {
    grid-template-areas: 
      "app-user app-toolbar"
      "app-orgs app-toolbar"
      "app-content app-toolbar";
      grid-template-rows: auto auto 1fr;
      grid-template-columns: 1fr auto;
    }
}

button.selected {
  font-weight: bolder;
  background-color: var(--anticipation-color);
}
button.selected::before {
  content: "> ";
}
