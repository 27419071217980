.user-container {
    display: grid;
    grid-area: app-user;
    grid-template-columns: auto 1fr auto auto;
    grid-template-areas: "user-logo user-info user-toolbar user-logout";
    gap: 5px;
}

.user-logo-center {
    grid-area: user-logo;
    padding: 5px;
}
.user-logo-letters-container {
    font-size: 45px;
    color: var(--anticipation-color);
    display: block;
    min-width: fit-content;
    white-space: nowrap;
    text-align: center;
}

.user-info {
    grid-area: user-info;
    overflow: hidden;
    font-size: large;
    margin: auto auto auto 0;
}

.user-toolbar {
    grid-area: user-toolbar;
}
