.stats-container {
    grid-area: app-content;
    min-width: 100%;
    min-height: 100%;
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
    border: 1px solid var(--text-color);
    border-radius: 5px;
    display: grid;
    grid-template-rows: auto 1fr auto;
    position: relative;
    padding-top: 0.5em;
}

.stats-container-label {
    position: absolute;
    background-color: var(--background-color);
    top: -0.5em;
    left: 0.5em;
    padding-left: 0.5em;
    padding-right: 0.5em;
}
.stats-filters-bar {
    border-bottom: 1px solid var(--text-color);
    padding: 15px;
    margin-left: 15px;
    margin-right: 15px;
}

.stats-content-container {
    overflow: auto;
    padding: 5px;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
}

.stats-tabs-container {
    display: flex;
    flex-flow: row;
    border-top: 1px solid var(--text-color);
    justify-content: left;
    align-items: baseline;
    padding-left: 5px;
}

.stats-tab-label {
    border: 1px solid var(--text-color);
    border-top-width: 0;
    margin-bottom: 5px;
    padding: 5px;
    border-radius: 0 0 5px 5px;
    position: relative;
    cursor: pointer;
}

.stats-tab-label.selected {
    color: var(--background-color);
    background-color: var(--text-color);
}

.stats-content_creating-container {
    display: grid;
    grid-template-areas:    "created count"
                            "calendar calendar";
}

.stats-content_assessing-container {
    display: grid;
    grid-template-areas:    "created            count-content           avg-vector  avg-vector"
                            "count-assessments  count-content-assessed  avg-vector  avg-vector "
                            "calendar           calendar                calendar    calendar";
}

.stats-user_assessmennts-container {
    display: grid;
    grid-template-areas:    
                            "count-assessments  avg-vector      avg-vector"
                            "count-users        avg-vector      avg-vector"
                            "pie-language       pie-age         pie-gender"
                            "content-items      content-items   content-items";
    grid-template-columns: 1fr 1fr 1fr;
}