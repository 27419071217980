.chat-container {
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-rows: 1fr auto;
    max-height: 100%;
    min-height: 100%;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}

.chat-messages{
    display: flex;
    overflow: auto;
    flex-flow: column-reverse;
    justify-items: right;
    gap: 0.25em;
    padding: 0.25em;
}

.chat-new-message {
    display: grid;
    grid-template-columns: auto 1fr auto;
}

.chat-message-my {
    text-align: right;
    display: grid;
    grid-template-rows: auto auto;
    --border-width: 1px;
    --border-style: solid;
    border-radius: 1em;
    background-color:antiquewhite;
    padding: 0.5em;
    width: fit-content;
    margin-left: auto;
}
.chat-message-my > span:nth-child(2){
    font-size: x-small;
    color: gray;
}

.chat-message-others {
    display: grid;
    grid-template-rows: auto auto;
    text-align: left;
    padding: 0.5em;
    width: fit-content;
    margin-right: auto;
    --background-color: ;
}
.chat-message-others > span:nth-child(2){
    font-size: x-small;
    color: gray;
}

.send-button {
    background-image: url('Eo_circle_green_arrow-up.svg');
    color: darkred;
    background-repeat: no-repeat;
    background-color: transparent;
    background-size: 100%;
    border-width: 0px;
    width:2em;
    height: 2em;
}