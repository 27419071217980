.login-container {
    display: grid;
    grid-template-rows: auto auto;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: var(--background-color);
}
.login-intro {
    position: relative;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    text-align: justify;
    padding: 10px;
}
.login-logo {
    text-align: center; 
    color: var(--joy-color);
}

@media screen and (orientation: landscape) and (min-width: 1400px) {
    .login-intro {
        margin-left: 20%;
        margin-right: 20%;
    }
    .login-logo {
        font-size: 150px;
    }
    .login-logo > img{
        height: 100px;
    }
}
  
@media screen and (orientation: landscape) and (max-width: 1400px) {
    .login-intro {
        margin-left: 12%;
        margin-right: 12%;
    }
    .login-logo > img{
        height: 50px;
    }
    .login-logo {
        font-size: 75px;
    }
}

@media (orientation: portrait) {
    .login-intro {
        margin-left: 10px;
        margin-right: 10px;
    }
    .login-logo {
        font-size: 75px;
    }
    .login-logo > img{
        height: 50px;
    }
}
  
.login-form {
    position: relative;
    width: fit-content;
    height: auto;
    min-height: fit-content;
    max-height: fit-content;
    margin: 0 auto auto auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: auto;
    border-radius: 5px;
    padding: 20px;
    display: grid;
    grid-template-rows: auto auto auto auto;
    padding: 10px;
    border-style: solid;
    border-width: 1px;
    gap: 5px;
}

.logged {
    display: none;
    border-width: 0px;
    position: absolute;
    opacity: 0.3;
    margin: auto auto 0 auto;
    top: auto;
    left: auto;
    right: 0;
    bottom: 0;
    grid-area: user-logout;
}

.versions {
    font-size: small;
    opacity: 0.5;
}

.login-state {
    display: grid;
    grid-template-columns: 1fr auto;
}

.login-tguserid, .login-authcode {
    display: grid;
    grid-template-columns: 1fr auto auto;
    gap: 5px;
}