.insights-app-container {
    display: grid;
    grid-template-rows: auto auto 1fr 1fr;
    max-width: 800px;
    gap: 1em;
    max-height: 100%;
    min-height: 100%;
    overflow: hidden;
}

.insights-app-container > div {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

:root {
    --plutchik-text-color: silver;
    --plutchik-background-color: white;
}