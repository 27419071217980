.assess-container {
    display: grid;
    grid-template-areas: 
        "organization-name organization-name"
        "content-item-name flower"
        "content-item content-item"
        "controls-label controls-label"
        "controls controls"
        "buttons buttons"
        ;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto auto 1fr auto auto auto;
    height: 100%;
    padding: 5pt;
    width: 100%;
    gap: 5px;
}
.assess-no-content {
    height: 100%;
    display: flex;
    justify-content: center;
    flex-flow: column;
    text-align: center;
    gap:10%
}

.assess-organization-name {
    grid-area: organization-name;
    height: 100%;
}

.assess-flower {
    grid-area: flower;
    max-width: fit-content;
    max-height: fit-content;
}

.assess-controls {
    grid-area: controls;
    margin-left: auto;
    margin-right: auto;
    display: flex;
}

.assess-controls-label {
    grid-area: controls-label;
    text-align: center;
    font-size: large;
}

.assess-content-item-name-area {
    grid-area: content-item-name;
    min-width: 100%;
    min-height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.assess-content-item-name {
    font-size: x-large;
    word-wrap: break-word;
}

.assess-content-item {
    grid-area: content-item;
    min-width: 100%;
    min-height: 100%;
    overflow: hidden;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.assess-content-item > img {
    max-width: 100%;
    max-height: 100%;
    border: 1px solid var(--tg-theme-text-color);
    padding: 1px;
}

.assess-buttons {
    grid-area: buttons;
    display: flex;
    justify-content: space-evenly;
}

.assess-buttons > button {
    font-size: large;
    border: 1px solid var(--tg-theme-secondary-bg-color);
    background-color: var(--tg-theme-button-color);
    color: var(--tg-theme-button-text-color);
    cursor: pointer;
}

.assess-buttons > button:hover {
    background-color: var(--tg-theme-accent-text-color);
}